<template>
  <v-dialog ref="menu" v-model="menu_status" :return-value.sync="actual_date" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="dated"
        :label="label"
        outlined
        dense
        readonly
        v-bind="attrs"
        v-on="on"
        :prepend-inner-icon="icons.mdiCalendar"
      />
    </template>
    <v-date-picker
      v-model="actual_date"
      no-title
      scrollable
      :min="minimum_date"
      @input="$emit('input', $event)"
      @blur="$emit('forceDateUpdate', actual_date)"
    >
      <v-spacer />
      <v-btn text color="primary" @click="menu_status = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="ok()"> OK </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import { mdiCalendar } from "@mdi/js";

export default {
  name: "DatePickerDialog",
  props: {
    label: {
      type: String,
      description: "Input Label",
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    minimum_date: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      menu_status: false,
      actual_date: null,
      icons: {
        mdiCalendar,
      },
    };
  },
  computed: {
    dated() {
      return this.value;
    },
  },
  methods: {
    ok() {
      this.$refs.menu.save(this.actual_date);
      this.$emit("forceDateUpdate", this.actual_date);
    },
  },
};
</script>
