<template>
  <v-container>
    <validation-observer ref="observer">
      <form class="p-2" @submit.prevent="submit">
        <v-row>
          <v-col cols="12" sm="6">
            <validation-provider v-slot="{ errors }" name="Asset Number" rules="required">
              <v-text-field
                v-model="radios.asset_number"
                :error-messages="errors"
                label="Asset Number"
                dense
                required
                outlined
              >
              </v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="6">
            <validation-provider v-slot="{ errors }" name="Serial Number" rules="required">
              <v-text-field
                v-model="radios.serial_number"
                :error-messages="errors"
                label="Serial Number"
                dense
                required
                outlined
              >
              </v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="6">
            <validation-provider v-slot="{ errors }" name="Type" rules="required">
              <v-text-field v-model="radios.type" :error-messages="errors" label="Type" dense required outlined>
              </v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="6">
            <validation-provider v-slot="{ errors }" name="Status" rules="required">
              <v-select
                v-model="radios.status"
                :items="radio_status"
                :error-messages="errors"
                item-text="name"
                item-value="name"
                menu-props="auto"
                label="Status"
                outlined
                required
                dense
                :prepend-inner-icon="icons.mdiHeartPulse"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="6">
            <validation-provider v-slot="{ errors }" name="License" rules="required">
              <v-text-field v-model="radios.license" :error-messages="errors" label="License" dense required outlined>
              </v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="6">
            <validation-provider v-slot="{ errors }" name="Expiry" rules="required">
              <date-picker-dialog
                v-model="radios.expiry"
                :minimum_date="`1950-01-01`"
                :error-messages="errors"
                :label="`Expiry`"
                @forceDateUpdate="forceDateUpdate"
              ></date-picker-dialog>
            </validation-provider>
          </v-col>
        </v-row>

        <v-btn type="submit" color="primary" class="ma-10 pa-5">
          Save
        </v-btn>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import {
  mdiAccountOutline,
  mdiCounter,
  mdiFormatColorFill,
  mdiClockTimeEightOutline,
  mdiEvPlugType1,
  mdiHeartPulse,
} from "@mdi/js";
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import DatePickerDialog from "@/components/DatePickerDialog.vue";
import util from "@/core/utils/misc";
import Request from "@/core/api/request";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "RadioRead",
  components: {
    ValidationProvider,
    ValidationObserver,
    DatePickerDialog,
  },
  props: {
    radios: {
      type: Object,
    },
  },
  data() {
    return {
      radio_status: [],
      icons: {
        mdiAccountOutline,
        mdiCounter,
        mdiFormatColorFill,
        mdiClockTimeEightOutline,
        mdiEvPlugType1,
        mdiHeartPulse,
      },
    };
  },
  created() {
    this.dependencieStatus();
  },
  methods: {
    updateBrownse() {
      this.$emit("updateBrownse");
    },
    async submit() {
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: "Wait while we process." });
        await Request.update(`${this.$IYA.URI.RADIOS__EDIT}`, this.radios).then(response => {
          if (response.data.success) {
            this.$swal(`Done !`, `Action accomplished`, `success`);
            this.updateBrownse();
          } else {
            this.$swal(`Failed !`, `${response.data.error}`, `error`);
          }
        });
        util.isLoading();
      }
    },
    async dependencieStatus() {
      const { data, status } = await Request.get(`${this.$IYA.URI.AIRCRAFT_STATUSES__BROWSE}`);
      if (status !== this.$IYA.HTTP.OK) {
        return;
      }
      if (data.data.length < 1) {
        return;
      }
      this.radio_status = data.data;
    },

    forceDateUpdate(value) {
      this.radios.expiry = value;
    },
  },
};
</script>
